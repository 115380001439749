<template>
    <ItemView v-if="hasContentToShow" :item="item" :index="index" :hasDoneButton="false">
        <v-card-text>
            <p class="text-body-1 text-primary">
                <span v-html="markdown(item.title)"></span>
                <speak-text-icon :text="allText" />
            </p>
            <!-- the style collapses multiple spaces into one, and preserves newlines -->
            <div class="text-body-1" v-html="markdown(displayedText)"></div>
            <v-btn class="no-padding" variant="text" size="small" color="primary"
                v-if="item.text.length > maxCharsShown" @click="isExpanded = !isExpanded">{{ isExpanded ?
                    $t('activity_preview.read_less') : $t('activity_preview.read_more') }}</v-btn>
        </v-card-text>
    </ItemView>
</template>

<script>
import ItemView from './ItemView.vue';
import SpeakTextIcon from './SpeakTextIcon.vue';

export default {
    components: {
        ItemView,
        SpeakTextIcon
    },
    extends: ItemView,
    data() {
        return {
            isExpanded: false,
            maxCharsShown: 700,
        };
    },
    computed: {
        hasContentToShow() {
            // true if title or text is not empty
            return this.item.title !== "" || this.item.text !== "";
        },
        displayedText() {
            if (this.isExpanded || this.item.text.length <= this.maxCharsShown) {
                return this.item.text;
            }
            // cutoff ensures that we don't cut off in the middle of a word
            let cutOff = this.item.text.slice(0, this.maxCharsShown).lastIndexOf(' ');
            if (cutOff === -1) {
                cutOff = this.maxCharsShown;
            }
            return this.item.text.slice(0, cutOff) + '...';
        },
        allText() { // used for text-to-speech
            return (this.item.title ? this.item.title + '. ' : '') + this.item.text;
        }
    },
}
</script>

<style scoped>
.no-padding {
    padding: 0 !important;
}
</style>