// https://stackoverflow.com/questions/64139078/escaping-a-pipe-in-a-vue-i18n-translation
export default {
  common: {
    server_connection_error: 'Server connection error.',
    link_to_activity: 'Link to Activity',
  },
  settings: {
    name: 'Teacher Name (displayed to students)',
    language: 'Default Language (for interface and activities)',
    saved_message: 'Settings saved successfully.',
    save_settings: 'Save Settings',
    premium_account: 'This is a <b>PREMIUM</b> account, allowing you to create unlimited activities.',
    regular_account: 'This is a <b>REGULAR</b> account, allowing you to create up to <b>15 activities for free</b>.',
    upgrade_to_premium: 'Interested in upgrading to <b>PREMIUM</b>? please contact:',
    downgrade_to_regular: 'Interested in downgrading to <b>REGULAR</b>? please contact:',
  },
  navbar: {
    create_activity: 'Activity Editor',
    my_activities: 'My Activities',
    student_answers: 'Answers',
    login: 'Login',
    logout: 'Logout',
    greeting: 'Hello',
    settings: 'Settings',
    help: 'Help',
  },
  help: {
    title_faq: 'Frequently Asked Questions',
    title_tutorials: 'Tutorials',
    contact_us: 'Contact us for suggestions, bug reports, or premium account:',
    tutorial_initial_login: 'About the system and first login',
    tutorial_create_first_activity: 'Creating the first activity',
    tutorial_activity_ai_1: 'Creating an activity with AI assistance - first example',
    tutorial_activity_components: 'Understanding activity components',
    q_difference_google_forms: 'How is it different from Google Forms?',
    a_difference_google_forms: 'Google Forms is a general-purpose form builder, while LetsStudy.io is a platform for creating interactive learning activities. It provides a variety of question types, including multiple choice, true/false, sort items, and more. It also offers a training mode, where students can keep answering questions until they succeed, and a quiz mode, where each question can be answered only once.',
    q_is_it_free: 'Do I need to pay to use the system?',
    a_is_it_free: 'It is free to create up to 15 activities. If you need more, you can upgrade to a premium account by contacting us (contact details below).',
    q_who_developed: 'Who is developing LetsStudy.io?',
    a_who_developed: 'LetsStudy.io is developed by Dr. Oren Mishali, a researcher and lecturer at the Technion and the Open University. This project is his personal initiative, independent of the institutions where he teaches.',
  },
  auth_screen: {
    welcome: "Let's Study",
    please_login: 'Login using your Google Acount',
    google_button: 'Login',
  },
  activity_editor: {
    material: 'Material',
    question: 'Question',
    explanation: 'Explanation',
    text: 'Text',
    image: 'Image',
    youtube: 'YouTube',
    youtube_url: 'YouTube URL',
    multiple_choice: 'Multiple Choice',
    multiple_choice_nodist: 'Multiple Choice (Auto Distractors)',
    true_false: 'True/False',
    sort_items: 'Sort Items',
    mark_words: 'Mark Words',
    short_answer: 'Short Answer',
    delete: 'Delete',
    title: 'Title',
    url: 'URL',
    caption: 'Caption',
    correct: 'Correct Answer',
    distractor: 'Distractor',
    mark_required: 'Mark required words',
    mark_optional: 'Mark optional words',
    main_title: 'Main',
    secondary_title: 'Secondary',
    save: 'Save',
    activity_saved: 'Activity saved successfully.',
    feedback: 'Explanation',
    true_false_statement: 'Statement',
    guideline: 'Guideline',
    item: 'Item',
    answer: 'Answer',
    card_view: "Card View",
    json_view: "JSON View",
    number_of_questions: "No questions | 1 question | {count} questions",
    activity_preview: "Preview in a new tab",
    short_answer_default_explanation: "Use correct answers as explanation",
    quota_left: "You have reached the maximum number of activities. | You can create one more activity. | You can create up to {count} more activities.",
    upgrade: "Upgrade",
  },
  wizard: {
    title: "Bulk Question Generator",
    multiple_choice_example_nodist: "What is the capital of Israel? {'|'} Jerusalem",
    multiple_choice_example: "What is the capital of Israel? {'|'} Jerusalem {'|'} Tel Aviv {'|'} Haifa {'|'} Be'er Sheva {'|'} 1 {'|'} Jerusalem is the capital of Israel.",
    multiple_choice_label_nodist: "Line structure: question {'|'} correct answer {'|'} explanation (optional)",
    multiple_choice_label: "Line structure: question {'|'} answer1 {'|'} ... {'|'} answer4 {'|'} correct index {'|'} explanation (optional)",
    true_false_label: "Line structure: statement {'|'} 1/0 {'|'} explanation (optional)",
    true_false_example: "Jerusalem is the capital of Israel {'|'} 1",
    sort_items_label: "Enter sorted items: Item 1 {'|'} Items 2 {'|'} ...",
    sort_items_example: "Sunday {'|'} Monday {'|'} Tuesday {'|'} Wednesday",
    short_answer_label: "Line structure: question {'|'} answer1 {'|'} answer2 {'|'} ...",
    short_answer_example: "What is the opposite of slow? {'|'} fast {'|'} quick",
    mark_words_label: "Line structure: question {'|'} text. Precede required words with *, optional with **",
    mark_words_example: "Mark the verbs. {'|'} Danny *plays the guitar. His brother *sings.",
    cancel: 'Cancel',
    generate: 'Generate Questions',
    close: 'Close',
  },
  activity_preview: {
    check: 'Check',
    activity_name: 'Activity Name',
    true: 'True',
    false: 'False',
    answer: 'Answer',
    words: "No words | 1 word | {count} words",
    read_more: 'Read More',
    read_less: 'Read Less',
  },
  activity_settings: {
    title: 'Activity Settings',
    interaction_mode: 'Interaction Mode',
    show_grade: 'Show grade for each question',
    save: 'Save',
    close: 'Close',
    link_copied: 'Link copied to clipboard.',
    activity_language: 'Activity Language',
  },
  my_activities: {
    create_activity: 'New Activity',
    create_folder: 'New Folder',
    share_activity: 'Share Activity',
    folder_name: 'Folder Name',
    new_activity_name: 'Activity with no name',
    confirm_activity_delete: 'Delete the activity?',
    confirm_folder_delete: 'Delete the folder?',
    folder_delete_message: 'Activities in the folder are not deleted and moved to the top-level folder.',
    delete: 'Delete',
    cancel: 'Cancel',
    confirm: 'Confirm',
    close: 'Close',
    no_activities: 'There are no activities in this folder.',
  },
  solve_activity: {
    text_to_speech: 'Read Aloud',
    select_voice: 'Select Voice',
    restart_activity: 'Start Over',
    training_help: 'Keep answering each question until you succeed.',
    quiz_help: 'Each question can be answered only once.',
    switch_to_quiz: 'Switch to Quiz',
    switch_to_training: 'Switch to Training',
    training_mode: 'Training Mode',
    quiz_mode: 'Quiz Mode',
    your_grade_is: 'Your grade is {grade}',
    my_name: 'My Name',
    send: 'Send',
    enter_your_name: 'You should enter your name.',
    sent_to_teacher: 'Successfully sent to teacher.',
    created_with: 'Created with LetsStudy.io',
  },
  answers: {
    activity_name: 'Activity Name',
    answered_at: 'Answered At',
    grade: 'Grade',
    student_name: 'Student Name',
  },
  certificate: {
    show_certificate: 'Show Certificate',
  }
  // other translations...
}